var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',{staticClass:"pa-4"},[_c('v-col',{staticClass:"d-flex"},[_c('div',{staticClass:"text-h5"},[_vm._v("Eszközök")]),_c('v-spacer'),_c('v-btn',{attrs:{"to":"/eszkozok/uj","color":"primary"}},[_vm._v("Hozzáadás")])],1)],1),_c('v-row',[_c('v-col',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"items-per-page":15},scopedSlots:_vm._u([{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"color":_vm.statusIconColor(item.status)}},[_vm._v(_vm._s(_vm.statusIcon(item.status)))])]}},{key:"item.lastStatus.cpuUsage",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.lastStatus.cpuUsage ? ((item.lastStatus.cpuUsage) + " %") : '')+" ")]}},{key:"item.lastStatus.cpuTemperature",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.lastStatus.cpuTemperature ? ((item.lastStatus.cpuTemperature) + " C˚") : '')+" ")]}},{key:"item.lastStatus.memoryUsage",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.lastStatus.memoryUsage ? ((item.lastStatus.memoryUsage) + " %") : '')+" ")]}},{key:"item.playlistId",fn:function(ref){
var item = ref.item;
return [_c('playlist-name',{attrs:{"id":item.playlistId}})]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"mr-2",attrs:{"to":("/eszkozok/" + (item.id)),"icon":""}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-pencil ")])],1),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.deleteItem = item}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-delete ")])],1)]}}],null,true)})],1)],1),(_vm.deleteItem)?_c('v-dialog',{attrs:{"width":"500"},model:{value:(_vm.deleteItem),callback:function ($$v) {_vm.deleteItem=$$v},expression:"deleteItem"}},[_c('v-card',[_c('v-card-title',[_vm._v("Biztosan törlöd a(z) "),_c('i',[_vm._v(_vm._s(_vm.deleteItem.name))]),_vm._v(" adatlapját?")]),_c('v-card-text'),_c('v-card-actions',[_c('v-btn',{on:{"click":function($event){_vm.deleteItem=null}}},[_vm._v("Mégse")]),_c('v-spacer'),_c('v-btn',{on:{"click":_vm.confirmDelete}},[_vm._v("Igen")])],1)],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }
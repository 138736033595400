<template>
  <v-container fluid>

    <v-row class="pa-4">
      <v-btn @click="save" :disabled="saveProgress" color="primary" :loading="saveProgress">Mentés</v-btn>
      <v-spacer></v-spacer>
      <v-btn small to="/allatok" >Vissza a listához</v-btn>
    </v-row>

    <v-form ref="form" v-model="valid">

      <v-row>
        <v-col cols="3">
          <v-card class="mb-8">
            <v-card-title>Térkép Poi</v-card-title>
              <v-card-text>
                <v-select :items="$map.categories" item-text="nameHu" item-value="id" label="Kategória" outlined v-model="poiCategory"></v-select>
                <v-select :disabled="!poiCategory" :items="pois" item-text="nameHu" item-value="id" label="Poi" outlined v-model="item.poiId"></v-select>
              </v-card-text>
            </v-card>
            <v-card>
              <v-card-title>Piktogrammok</v-card-title>
              <v-card-subtitle>145 x 145px - max 6d<br><small>jpg, png, svg, webp</small></v-card-subtitle>
              <v-card-text>
                <multiple-image-uploader :size="6" :max="6" :ratio="1/1" v-model="item.pictogramImageIds"></multiple-image-uploader>
              </v-card-text>
            </v-card>
        </v-col>
        <v-col cols="9">
          <v-card class="mb-4">
            <v-card-title>Alapadatok</v-card-title>
            <v-card-text>
              <v-tabs v-model="tab">
                <v-tab>
                  HU
                </v-tab>
                <v-tab>
                    EN
                    <span style="color:red" v-if="!item.nameEn">*</span>
                </v-tab>
              </v-tabs>
              <v-tabs-items v-model="tab">
                <v-tab-item class="pt-4">
                  <v-text-field label="Megnevezés - HU" :rules="[rules.required]" outlined v-model="item.nameHu"></v-text-field>
                  <v-textarea maxlength="500" counter="500" :rules="[rules.required]" rows="16" label="Leírás - HU" outlined v-model="item.descriptionHu"></v-textarea>
                </v-tab-item>
                <v-tab-item class="pt-4" eager>
                  <v-text-field class="english" label="Megnevezés - EN" :rules="[rules.required]" outlined v-model="item.nameEn"></v-text-field>
                  <v-textarea class="english" maxlength="500" counter="500" :rules="[rules.required]" rows="16" label="Leírás - EN" outlined v-model="item.descriptionEn"></v-textarea>
                </v-tab-item>
              </v-tabs-items>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <v-card>
            <v-card-title>Galéria képek</v-card-title>
            <v-card-subtitle>1920 x 1080px <small>jpg, png, svg, webp</small></v-card-subtitle>
            <v-card-text>
              <multiple-image-uploader :size="2" :ratio="1920/1080" v-model="item.galleryImageIds"></multiple-image-uploader>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>


      <v-row>
        <v-col cols="6">
            <v-card class="fill-height">
              <v-card-title>Háttérkép</v-card-title>
              <v-card-subtitle>1920 x 1080px <small>jpg, png, svg, webp</small></v-card-subtitle>
              <v-card-text>
                  <single-image-uploader :ratio="1920/1080" v-model="item.backgroundImageId"></single-image-uploader>
              </v-card-text>
            </v-card>
        </v-col>
        <v-col cols="3">
          <v-card class="fill-height">
            <v-card-title>Végoldali kép</v-card-title>
            <v-card-subtitle>500 x 740px <small>jpg, png, svg, webp</small></v-card-subtitle>
            <v-card-text>
              <single-image-uploader :ratio="500/740" v-model="item.mainImageId"></single-image-uploader>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="3">
          <v-card class="fill-height">

            <v-card-title>Listakép</v-card-title>
            <v-card-subtitle>300 x 325px <small>jpg, png, svg, webp</small></v-card-subtitle>
            <v-card-text>
              <v-input :rules="[rules.requiredImage]" v-model="item.listImageId"></v-input>

              <single-image-uploader :ratio="300/325" v-model="item.listImageId"></single-image-uploader>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>


    </v-form>
    <v-row>
      <v-col>

      </v-col>
    </v-row>

  </v-container>
</template>
<script>

  import db from '../plugins/db.js';
  import SingleImageUploader from '../components/SingleImageUploader.vue'
  import MultipleImageUploader from "../components/MultipleImageUploader";

  export default {
    components : {
      MultipleImageUploader,
      SingleImageUploader
    },

    data() {
      return {
        poiCategory:null,
        valid:false,
        saveProgress:false,
        rules: {
          required: value => !!value || 'Kötelező kitölteni.',
          requiredImage: value => !!value || 'Kötelező feltölteni.',
        },
        animals : db.animals,
        showSavedSnackbar:false,
        tab:null,
        item : {
          nameHu:'',
          nameEn:'',
          descriptionHu:'',
          descriptionEn:'',
          galleryImageIds:[],
          pictogramImageIds:[],
          listImageId:null,
          mainImageId:null,
          backgroundImageId:null,
          poiId:null
        }
      }
    },

    computed : {
      isNew(){
        return this.$route.params.id === 'uj'
      },
      pois () {
        if (!this.poiCategory) {
          return []
        }

        return this.$map.pois.filter(x => x.categoryId == this.poiCategory)
      }
    },

    methods : {
      nah (e, b) {
        console.log('nah', e,  b)

      },
      save() {
        this.$refs.form.validate();
        if (!this.valid) {
          const el = document.querySelector('.v-input.error--text');
          if (el) {
            if (el.classList.contains('english')) {
              this.tab = 1
            }
            const y = el.getBoundingClientRect().top - 100;
            window.scrollTo({top: y, behavior: 'smooth'});
          }

          return true;
        }

        this.saveProgress = true;
        this.$api.post('/animal/save', this.item).then((response) => {
          this.$snackbar.show('Módosítások elmentve')
          if (this.isNew) {
            this.$router.push(`/allatok/${response.data.id}`)
          }
          this.saveProgress = false;
        })
      }
    },

    mounted () {
      if (!this.isNew) {
        this.$api.get(`/animal/${this.$route.params.id}`).then((resp)=>{
          this.item = resp.data;

          if (this.item.poiId) {
              const poi = this.$map.pois.find(x => x.id == this.item.poiId);
              console.log('poi', poi)
              if (poi) {
                console.log(poi.categoryId, poi)
                this.poiCategory = poi.categoryId
              }
          }
        })
      }
    }
  }
</script>
<template>
	<v-container fluid>
		<v-row class="pa-4">
      <v-col class="d-flex">
        <div class="text-h5">Eszközök</div>
        <v-spacer></v-spacer>
        <v-btn to="/eszkozok/uj" color="primary">Hozzáadás</v-btn>
      </v-col>
		</v-row>
		<v-row>

			<v-col>

				<v-data-table
					:headers="headers"
					:items="items"
					:items-per-page="15"
				>
          <template v-slot:[`item.status`]="{ item }">
            <v-icon :color="statusIconColor(item.status)">{{ statusIcon(item.status) }}</v-icon>
          </template>

					<template v-slot:[`item.lastStatus.cpuUsage`]="{ item }">
						{{ item.lastStatus.cpuUsage ? `${item.lastStatus.cpuUsage} %` : '' }}
					</template>

					<template v-slot:[`item.lastStatus.cpuTemperature`]="{ item }">
						{{ item.lastStatus.cpuTemperature ? `${item.lastStatus.cpuTemperature} C˚` : '' }}
					</template>
					<template v-slot:[`item.lastStatus.memoryUsage`]="{ item }">
						{{ item.lastStatus.memoryUsage ? `${item.lastStatus.memoryUsage} %` : '' }}
					</template>

					<template v-slot:[`item.playlistId`]="{ item }">
						<playlist-name :id="item.playlistId"></playlist-name>
					</template>


					<template v-slot:[`item.actions`]="{ item }">
						<v-btn :to="`/eszkozok/${item.id}`" icon class="mr-2">
							<v-icon
								small
							>
								mdi-pencil
							</v-icon>
						</v-btn>
						<v-btn icon @click="deleteItem = item">
							<v-icon
							small
							>
							mdi-delete
						</v-icon></v-btn>
					</template>
				</v-data-table>


			</v-col>
		</v-row>


		<v-dialog v-if="deleteItem" v-model="deleteItem" width="500">
			<v-card>
				<v-card-title>Biztosan törlöd a(z) <i>{{deleteItem.name}}</i> adatlapját?</v-card-title>
				<v-card-text>
				</v-card-text>
				<v-card-actions>
					<v-btn @click="deleteItem=null">Mégse</v-btn>
					<v-spacer></v-spacer>
					<v-btn @click="confirmDelete">Igen</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</v-container>
</template>
<script>

	import PlaylistName from '../components/PlaylistName.vue'

	export default {
		components : {
			PlaylistName
		},
		data() {
			return {
				headers: [
					{text: 'ID', value: 'id'},
					{text: 'Név', value: 'name'},
					{text: 'Státusz', value: 'status'},
					{text: 'Lejátszási lista', value: 'playlistId'},
					{text: 'Cpu használat', value: 'lastStatus.cpuUsage'},
					{text: 'Cpu hőmérséklet', value: 'lastStatus.cpuTemperature'},
					{text: 'Memória használat', value: 'lastStatus.memoryUsage'},
					{text: 'Műveletek', value: 'actions', sortable:false, width: 120},
				],
				items: [],
				deleteItem: null,
				editedItem: null,
			}
		},

		methods : {
      statusIconColor(status)
      {
        return {
          0 : 'error',
          1 : 'success',
          21 : 'orange',
          22 : 'orange',
          31 : 'orange',
          32 : 'orange',
        }[status]
      },
      statusIcon(status)
      {
        return {
          0 : 'mdi-alert-circle',
          1 : 'mdi-check-circle',
          21 : 'mdi-refresh-circle',
          22 : 'mdi-refresh-circle',
          31 : 'mdi-power',
          32 : 'mdi-power',
        }[status]
      },
			confirmDelete() {
				this.$api.get(`/device/delete/${this.deleteItem.id}`).then(()=>{
					this.deleteItem = null;
					this.loadItems();
				})
			},
			loadItems () {
				this.$api.get(`/device`).then((resp)=>{
					this.items = resp.data
				})
			}
		},

		mounted () {
			this.loadItems()
		}
	}
</script>
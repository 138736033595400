<template>
	<v-card-title class="d-flex align-center justify-center flex-column pb-8 relative">
		<h4 class="text-wrap">{{ title }}</h4>
	</v-card-title>
</template>
<script>

	export default {
		components : {
		},
		props: {
			title: String
		},
		data () {
			return {
			}
		}
	}
</script>
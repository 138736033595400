<template>
	<v-container fluid>

		<v-row class="pa-4">
      <v-btn  @click="save" color="primary">Mentés</v-btn>
			<v-spacer></v-spacer>
			<v-btn small to="/eszkozok" >Vissza a listához</v-btn>
		</v-row>

		<v-row>

			<v-col>
				<v-card>
					<v-card-title>Alapadatok</v-card-title>
					<v-card-text>
            <v-text-field label="Megnevezés" outlined v-model="item.name"></v-text-field>
            <v-select :items="playlists" item-text="name" item-value="id" label="Videó lejátszási lista" outlined v-model="item.playlistId"></v-select>
					</v-card-text>


				</v-card>
			</v-col>

      <v-col>
        <v-card>
          <v-card-title>Térkép poi</v-card-title>
          <v-card-text>


            <v-select :items="$map.categories" item-text="nameHu" item-value="id" label="Kategória" outlined v-model="poiCategory"></v-select>
            <v-select :disabled="!poiCategory" :items="pois" item-text="nameHu" item-value="id" label="Poi" outlined v-model="item.poiId"></v-select>

          </v-card-text>

        </v-card>
      </v-col>

			<v-col md="4" lg="3">
				<v-card class="mb-4">
					<v-card-title><v-icon :color="statusIconColor">{{ statusIcon }}</v-icon>{{ statusText }}</v-card-title>
					<v-card-text>
              <v-list-item>
              <v-list-item-content>
                <v-list-item-subtitle>Utolsó életjel</v-list-item-subtitle>
                <v-list-item-title>{{ lastPing }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-subtitle>Utolsó adat áttöltés</v-list-item-subtitle>
                <v-list-item-title>{{ lastSync }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

					</v-card-text>
				</v-card>


				<v-card>
					<v-card-title>Vezérlés</v-card-title>
					<v-card-actions>
						<v-btn small @click="doRestart">Újraindítás</v-btn>
						<v-spacer></v-spacer>
						<v-btn small @click="doShutdown">Leállítás</v-btn>
					</v-card-actions>
				</v-card>
			</v-col>


		</v-row>

		<v-row>
			<v-col>
				<v-card>
					<v-card-title>Cpu használat</v-card-title>
					<v-card-text class="pb-14">
						<div class="cpu-usage" ref="cpuChart"></div>
					</v-card-text>
				</v-card>
			</v-col>
		</v-row>

		<v-row>
			<v-col>
				<v-card>
					<v-card-title>Memória használat</v-card-title>
					<v-card-text class="pb-14">
						<div class="memory-usage" ref="memoryChart"></div>
					</v-card-text>
				</v-card>
			</v-col>
		</v-row>

		<v-row>
			<v-col>
				<v-card>
					<v-card-title>Cpu hőmérséklet</v-card-title>
					<v-card-text class="pb-14">
						<div class="cpu-temp" ref="tempChart"></div>
					</v-card-text>
				</v-card>
			</v-col>
		</v-row>




	</v-container>
</template>
<script>

	import Chartist from 'chartist'
	import moment from 'moment'


	export default {

		components: {
		},

		data() {
			return {
				item : {
					name: ''
				},
				headers:[],
				cpuUsages:[],
				cpuTemperatures:[],
				memoryUsages:[],
				playlists:[],
        poiCategory:null,
			}
		},

		computed : {
      pois () {
        if (!this.poiCategory) {
          return []
        }

        return this.$map.pois.filter(x => x.categoryId == this.poiCategory)
      },
			isNew(){
				return this.$route.params.id === 'uj'
			},
			lastPing () {
				if (!this.item.lastStatus) {
					return null;
				}
				return moment(this.item.lastStatus.createdAt).locale('hu').fromNow()
			},
      lastSync () {
				if (!this.item.lastSynced) {
					return null;
				}
				return moment(this.item.lastSynced).locale('hu').fromNow()
			},
			statusIconColor()
			{
				return {
					0 : 'error',
					1 : 'success',
					21 : 'orange',
					22 : 'orange',
					31 : 'orange',
					32 : 'orange',
				}[this.item.status]
			},
			statusIcon()
			{
				return {
					0 : 'mdi-alert-circle',
					1 : 'mdi-check-circle',
					21 : 'mdi-refresh-circle',
					22 : 'mdi-refresh-circle',
					31 : 'mdi-power',
					32 : 'mdi-power',
				}[this.item.status]
			},
			statusText()
			{
				return {
					0 : 'Kikapcsolva',
					1 : 'Aktív',
					21 : 'Újraindítási parancs elküldve',
					22 : 'Újraindítás folyamatban',
					31 : 'Leállítási parancs elküldve',
					32 : 'Leállítás folyamatban',
				}[this.item.status]
			},
		},

		methods : {
			save() {

				this.$api.post('/device/save', this.item).then((response) => {
					this.$snackbar.show('Módosítások elmentve')
					if (this.isNew) {
						this.$router.push(`/eszkozok/${response.data.id}`)
					}
				})

			},

			doRestart () {
				this.$api.post(`/device/restart/${this.item.id}`).then(() => {
					this.$snackbar.show('Újraindítási parancs elküldve')
				})
			},

			doShutdown () {
				this.$api.post(`/device/shutdown/${this.item.id}`).then(() => {
					this.$snackbar.show('Leállítási parancs elküldve')
				})
			},

			loadStatuses () {

				this.headers = [];
					this.cpuUsages = [];
					this.cpuTemperatures = [];
					this.memoryUsages= [];

				this.$api.get(`/device-status/list/${this.$route.params.id}`).then((resp)=>{
					resp.data.forEach((status) => {
						this.cpuUsages.push(status.cpuUsage);
						this.memoryUsages.push(status.memoryUsage);
						this.cpuTemperatures.push(status.cpuTemperature);


						this.headers.push(moment(status.createdAt).locale('hu').format('MMM Do hh:mm'));
					});

					new Chartist.Line(this.$refs.cpuChart, {
						labels: this.headers,
						series: [{ data: this.cpuUsages }]
					})
					new Chartist.Line(this.$refs.memoryChart, {
						labels: this.headers,
						series: [ { data: this.memoryUsages } ]
					})
					new Chartist.Line(this.$refs.tempChart, {
						labels: this.headers,
						series: [ { data: this.cpuTemperatures } ]
					})
				})
			},

      loadPoi () {
        const poi = this.$map.pois.find(x => x.id == this.item.poiId)
        if (poi) {
          const category = this.$map.categories.find(x => x.id == poi.categoryId)
          if (category) {
            this.poiCategory = category.id
          }
        }
      },
		},

		mounted () {
			this.$api.get(`/playlist`).then((resp)=>{
				this.playlists = resp.data;
			});

			if (!this.isNew) {
				this.$api.get(`/device/${this.$route.params.id}`).then((resp)=>{
					this.item = resp.data;

          if (this.item.poiId) {
            this.loadPoi()
          }
				});
				this.loadStatuses()
			}
		}
	}
</script>
import Vue from 'vue'

var snackbar = new Vue({

  data: () => ({
    isOpen: false,
    timeout: 3000,
    text:'dsadas',
  }),

  computed : {
  },

  methods: {
    hide () {
      this.isOpen = false;
    },
    show(title) {
      this.text = title;
      this.isOpen = true;
    }
  },

  created() {

  },
})

export default {
  install (Vue) {
    Vue.prototype.$snackbar = snackbar
  }
}
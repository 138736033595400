import Vue from 'vue'

var menu = new Vue({

	data: () => ({
		windowWidth: window.innerWidth,
		isOpen: null,
	}),

	computed : {
	},

	methods: {

		onResize () {
			this.windowWidth = window.innerWidth
		},
		toggleMenu () {
			this.isOpen = !this.isOpen;
			window.localStorage.setItem('menuopen', JSON.stringify(this.isOpen))
		},
		setDefaultMenuStatus()
		{
			if (window.localStorage.getItem('menuopen') === null) {
				this.isOpen = true;
				return true;
			}
			this.isOpen = JSON.parse(window.localStorage.getItem('menuopen'));
		}
	},

	created() {
		this.setDefaultMenuStatus();
	},
})

export default {
	install (Vue) {
		Vue.prototype.$menu = menu
	}
}
import Vue from 'vue'
import axios from 'axios'
import api from './api'

var auth = new Vue({
  data: () => ({
    jwt: '',
    loading: true,
    formState:'login',
  }),

  computed : {
    loggedIn () {
      return this.jwt
    },
    isDialogOpen () {
      return !this.loggedIn
    },
    verifyToken () {
      return (new URLSearchParams(window.location.search)).get('verifyToken')
    }
  },

  methods: {
    login (jwt) {
      this.jwt = jwt;
      window.localStorage.setItem('jwt', jwt);
      axios.defaults.headers.jwt = jwt;
    },
    logout () {
      this.jwt = null;
      window.localStorage.removeItem('jwt');
      axios.defaults.headers.jwt = null;
    },
    verify () {
      return api.$api.get(`/user/verify`, {headers: {jwt: this.jwt}})
    }
  },

  created() {
    this.jwt = window.localStorage.getItem('jwt');

    if (this.verifyToken) {
      this.formState = 'password-reset'
    }
  },

  watch : {
    isDialogOpen (newValue) {
      if (newValue === false) {
        this.formState = 'login'
      }
    }
  }
})

export default {
  install(Vue) {
    Vue.prototype.$auth = auth
  },
  $auth: auth
};
<template>
  <svg :viewBox="`0 0 22 22`" :width="width" :id="`poi-${this.poi.id}`" :height="width" :x="x" :y="y" class="poi" :class="dragClass" @mousedown="startDrag" @mouseup="stopDrag" @mousemove="moveDrag">
    <rect class="poi-hover-rect" x="-50" y="-50" width="200" height="200" fill="transparent"></rect>
    <g v-if="category.shape == 1">
      <path fill="#FFF" d="M3.5,11.2c0.1,2.2,1.1,4.1,2.6,5.5l5.1,4.9l4.8-5.1c1.6-1.4,2.5-3.5,2.4-5.8c-0.1-4.1-3.6-7.4-7.7-7.3
  C6.6,3.6,3.4,7,3.5,11.2"/>
      <path :fill="category.color" d="M8.8,4.9c3.4-1.2,7,0.6,8.2,3.9c1.2,3.4-0.6,7-3.9,8.2c-3.3,1.2-7-0.6-8.2-3.9C3.7,9.8,5.5,6.1,8.8,4.9"/>


    </g>

    <g v-if="category.shape == 2">
      <path :fill="category.color" stroke="#FFF" stroke-miterlimit="10" d="M8.7,4.4c3.6-1.3,7.6,0.6,8.9,4.3c1.3,3.6-0.6,7.6-4.3,8.9
  c-3.6,1.3-7.6-0.6-8.9-4.2C3.1,9.7,5,5.7,8.7,4.4"/>
    </g>

    <g v-if="category.shape == 3">
      <path :fill="category.color" stroke="#FFF" stroke-miterlimit="10" d="M7.3,4C5.5,4,4,5.5,4,7.3v7.3C4,16.5,5.5,18,7.3,18h7.3
  c1.8,0,3.3-1.5,3.3-3.3V7.3C18,5.5,16.5,4,14.7,4H7.3z"/>
    </g>


    <g v-if="category.contentType == 1">
      <image ref="image" :width="imageWidth" :href="image" x="50%" y="50%" :transform="imageTransform"></image>
    </g>
    <g v-else-if="category.contentType == 2">
      <text x="11" y="11.5" font-size="8" font-family="Arial"  text-anchor="middle" alignment-baseline="middle" fill="#FFF">{{ poi.text }}</text>
    </g>
  </svg>
</template>

<script>
export default {
  props: ['poi'],
  data () {
    return {
      category: {},
      image: null,
      drag: false,
      startX:this.poi.x1,
      startY:this.poi.y1,
      img: null,
      imgW:0,
      imgH:0,
    }
  },
  computed : {
    dragClass () {
      return this.drag ? 'drag' : ''
    },
    hasImageContent () {
      return this.category.contentType == 1 && this.category.shape != 4
    },
    width () {
      return this.hasImageContent
          ? this.$map.width / 40
          : this.$map.width / 50
    },
    imageWidth () {
      return this.hasImageContent
          ? 9
          : 18
    },
    x () {
      return this.poi.x1 - (this.width / 2)
    },
    y () {
      return this.poi.y1 - (this.width / 2)
    },
    imageTransform () {
      if (!this.imgW) {
        return ''
      }
      const ratio = this.imgW / this.imgH;
      return `translate(-4.5 -${4.5/ratio})`
    }
  },

  methods : {

    startDrag () {
      this.drag = true;
    },

    stopDrag () {
      this.drag = false;
      this.poi._vm = this;
      this.$map.editedPoi = this.poi;
    },

    moveDrag (e) {
      if (!this.drag) {
        return
      }

      const xratio = this.$map.svg.clientWidth / this.$map.svg.viewBox.baseVal.width;
      const yratio = this.$map.svg.clientHeight / this.$map.svg.viewBox.baseVal.height;

      this.poi.x1 = e.layerX / xratio;
      this.poi.y1 = e.layerY / yratio;

      const nearest = this.$map.findNearest(this.poi.x1, this.poi.y1)

      this.poi.x2 = nearest.x
      this.poi.y2 = nearest.y
    },
    resetPosition () {
      const nearest = this.$map.findNearest(this.startX, this.startY)

      this.poi.x1 = this.startX;
      this.poi.y1 = this.startY;
      this.poi.x2 = nearest.x
      this.poi.y2 = nearest.y
    },

    loaded () {
      this.imgW = this.img.width;
      this.imgH = this.img.height;
    }
  },

  mounted () {
    this.$api.get(`/poi-category/${this.poi.categoryId}`)
        .then(response => this.category = response.data)

    if (this.poi.imageId) {
      this.$api.get(`/image/${this.poi.imageId}`).then((resp) => {
        this.image = `${process.env.VUE_APP_API_BASE_URL}/static/${resp.data.filename}`;

        this.img = new Image()
        this.img.onload = this.loaded
        this.img.src = this.image

      })
    }
  }
}
</script>
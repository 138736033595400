<template>
  <v-card>
    <v-card-title>
      Poik
      <v-spacer></v-spacer>
      <v-row dense>
        <v-spacer></v-spacer>
        <v-col cols="4">
<v-select
          v-model="searchCategory"
          label="Kategória"
          clearable
          outlined
          dense
          single-line
          hide-details
          :items="$map.categories" item-text="nameHu" item-value="id"
      ></v-select>
      </v-col>

      <v-col cols="4">
<v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          clearable
          label="Keresés"
          outlined
          dense
          single-line
          hide-details
      ></v-text-field>
      </v-col>
      </v-row>
      

      
    </v-card-title>
    <v-data-table
        :headers="headers"
        :items="pois"
        :search="search"
        :items-per-page="15"
    >
      <template v-slot:[`item.actions`]="{ item }">
        <v-btn @click="editPoi(item)" icon class="mr-2">
          <v-icon small>
            mdi-pencil
          </v-icon>
        </v-btn>
        <v-btn icon @click="$map.deletePoi=item">
          <v-icon
              small
          >
            mdi-delete
          </v-icon>
        </v-btn>
      </template>

    </v-data-table>



    <v-dialog v-if="$map.editedPoi" v-model="$map.editedPoi" max-width="400" persistent>
      <v-form v-model="valid" ref="form">
        <v-card>
        <v-card-title v-if="$map.editedPoi.id">Poi szerkesztése</v-card-title>
        <v-card-title v-else>Új poi adatai</v-card-title>
        <v-card-text>

          <v-tabs  v-model="langTab">
            <v-tab>
              HU
            </v-tab>
            <v-tab>
              EN
            </v-tab>
          </v-tabs>
          <v-tabs-items v-model="langTab">
            <v-tab-item class="pt-4">
              <v-text-field outlined label="Megnevezés - HU" v-model="$map.editedPoi.nameHu"></v-text-field>
            </v-tab-item>
            <v-tab-item class="pt-4">
              <v-text-field outlined label="Megnevezés - EN" v-model="$map.editedPoi.nameEn"></v-text-field>
            </v-tab-item>
          </v-tabs-items>

          <v-select label="Típus" outlined v-model="$map.editedPoi.categoryId" :items="$map.categories" item-text="nameHu" item-value="id"></v-select>


          <v-select v-if="$map.getEditedPoiCategory().nearest" label="Csoport" outlined v-model="$map.editedPoi.group" :items="groups" item-text="text" item-value="id"></v-select>



          <single-image-uploader v-if="$map.getEditedPoiCategory().contentType == 1" v-model="$map.editedPoi.imageId"></single-image-uploader>
          <v-text-field v-if="$map.getEditedPoiCategory().contentType == 2" v-model="$map.editedPoi.text" label="Sorszám" outlined></v-text-field>


        </v-card-text>
        <v-card-actions>
          <v-btn @click="resetPoi">Mégse</v-btn>
          <v-spacer></v-spacer>
          <v-btn @click="savePoi">Mentés</v-btn>
        </v-card-actions>
      </v-card>
      </v-form>
    </v-dialog>

    <v-dialog v-if="$map.deletePoi" :value="true" max-width="400">
      <v-card>
        <v-card-title>Biztosan törlöd a(z) <i>{{$map.deletePoi.nameHu }}</i> helyet?</v-card-title>
        <v-card-text>
        </v-card-text>
        <v-card-actions>
          <v-btn @click="$map.deletePoi=null">Mégse</v-btn>
          <v-spacer></v-spacer>
          <v-btn @click="$map.doDeletePoi()">Igen</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>


  </v-card>

</template>

<script>

import SingleImageUploader from "@/components/SingleImageUploader";


export default {
  components: {
    SingleImageUploader
  },
  props : [
      'categories'
  ],
  data() {
    return {
      langTab: null,
      search: '',
      searchCategory: '',
      items: [],
      currentLocation: null,
      valid:false,
      lastState:null,
      rules: {
        required: value => !!value || 'Kötelező kitölteni.',
      },
      headers: [
        {
          text: 'Megnevezés',
          value: 'nameHu',
        },
        {text: 'Műveletek', value: 'actions', sortable:false, width: 120},

      ],
      groups: [
        {
          id: 1,
          text: 'WC',
        },
        {
          id: 2,
          text: 'Dohányzóhely',
        },
        {
          id: 3,
          text: 'Pénztár',
        },
      ]
    }
  },

  computed : {
    pois () {
      if (!this.searchCategory) {
        return this.$map.pois;
      }

      return this.$map.pois.filter(x => x.categoryId == this.searchCategory);
    }
  },

  methods : {
    resetPoi () {
      if (this.$map.editedPoi._vm) {
        this.$map.editedPoi._vm.resetPosition();
      }
      for (var i in this.lastState) {
        this.$map.editedPoi[i] = this.lastState[i]
      }
      this.$map.editedPoi = null;
    },
    editPoi (item) {
      this.lastState = JSON.parse(JSON.stringify(item));
      this.$map.editedPoi = item;
    },
    savePoi () {
      this.$refs.form.validate();
      if (!this.valid) {
        return true;
      }
      this.$map.savePoi()
    }
  },
  mounted() {

  },
  watch : {

  }
}

</script>
<template>
  <v-responsive class="uploader-placeholder video" :aspect-ratio="16/9">
    <video v-show="loaded" ref="video" :src="src" muted autoplay></video>

    <div v-if="needLoader" class="preload-box fill-height justify-center align-center d-flex flex-column">
      <v-progress-circular color="primary" indeterminate></v-progress-circular>
      <div class="progress">
        <div class="percent">{{ percent }} %</div>
        <div class="bytes">{{ showbyte(uploadedSize) }} / {{ showbyte(totalSize) }}</div>
      </div>
    </div>
    <template v-else-if="loaded">
      <v-btn @click="deleteImage" fab elevation="2" class="delete-button" color="error">
        <v-icon >mdi-close</v-icon>
      </v-btn>
    </template>
    <div v-else-if="hasError" class="upload-error">
      <div class="title">Nem megfelelő formátum!</div>
      <div class="text">
        Engedélyezett formátum <b>mp4</b> vagy <b>webm</b> és maximum <b>500 Mb</b>
      </div>
      <v-btn @click="clearError" color="mt-6" small>Másik fájl választása</v-btn>

    </div>
    <label v-else class="fill-height">

      <input ref="files" accept="video/mp4, video/webm, video/avi" @change="fileChanged" type="file">
      <v-icon>mdi-upload</v-icon>
      <small>tallózás</small>
      <small>1920x1080 - mp4, webm</small>

      <div>
        <v-input :rules="[rules.required]" v-model="valueCopy"></v-input>
      </div>

    </label>
  </v-responsive>
</template>

<script>

  export default {

    components : {
    },

    props: [
      'value',
      'ratio',
    ],
    data() {
      return {
        rules : {
          required: value => !!value || 'Válassz videót',
        },
        src: null,
        loaded:false,
        uploading:false,
        hasError:false,
        totalSize:0,
        uploadedSize:0,
        percent:0,
      }
    },

    computed : {
      valueCopy () {
        return JSON.parse(this.value);
      },
      needLoader(){
        return this.uploading || (this.src && !this.loaded)
      }
    },

    methods : {
      showbyte (byte) {
        const mb = byte / 1024 / 1024;

        return mb.toFixed(2) + ' MB'
      },
      deleteImage (){
        this.src = null;
        this.loaded = false;
        this.$emit('input', null);
      },
      uploaded (response) {
        this.src = `${process.env.VUE_APP_API_BASE_URL}/static/${response.data[0].filename}`
        this.$emit('input', response.data[0].id)
        this.uploading = false;
        this.$emit('uploading', false);

      },
      uploadProgress (progressEvent) {
         this.totalSize = progressEvent.total
         this.uploadedSize = progressEvent.loaded
         this.percent = (this.uploadedSize / this.totalSize * 100).toFixed(1)
      },
      clearError () {
        this.uploading = false;
        this.hasError = false;
      },
      handleError () {
        this.uploading = false;
        this.hasError = true;
      },
      fileChanged () {
        this.uploading = true;
        this.$emit('uploading', true);
        this.$api.post('/image/upload', {images: this.$refs.files.files[0]}, {
          onUploadProgress: this.uploadProgress,
          headers: {
            "Content-Type": "multipart/form-data"
          }
        })
          .then(this.uploaded)
          .catch(this.handleError)

      },
      loadImage() {
        this.$api.get(`/image/${this.value}`).then((resp) => {
          this.src = `${process.env.VUE_APP_API_BASE_URL}/static/${resp.data.filename}`;
        })
      }
    },

    mounted() {

      if (this.value) {
        this.loadImage()
      }

      this.$refs.video.addEventListener('canplay', () => {
        this.loaded = true;
      });
    },

    watch : {
      value (newValue) {
        console.log('newValue', newValue)
        if (newValue) {
          this.loadImage()
        }
        else {
          this.image = null;
        }
      }
    }
  }
</script>
<template>
  <v-img :aspect-ratio="ratio" class="image" :src="src" @load="loaded = true">
    <div v-if="src && !loaded" class="preload-box fill-height justify-center align-center d-flex">
      <v-progress-circular color="primary" indeterminate></v-progress-circular>
    </div>
    <v-btn v-if="src" @click="emitDelete" fab elevation="2" color="error" class="delete-button"  x-small>
      <v-icon small >mdi-close</v-icon>
    </v-btn>
  </v-img>
</template>

<script>

  export default {

    props: [
      'id',
      'ratio'
    ],
    data() {
      return {
        loaded:false,
        src:''
      }
    },

    methods : {
      emitDelete () {
        this.$emit('imageDelete', this.id)
      }
    },

    mounted() {
      this.$api.get(`/image/${this.id}`).then((resp) => {
        this.src = `${process.env.VUE_APP_API_BASE_URL}/static/${resp.data.filename}`;
      })
    }
  }
</script>
export default  {
  animals : [
    {
      id: 1,
      name: 'Oroszlan',
      text: 'Az India-ház, illetve a hozzá tartozó nagyobbik kifutó lakói az indiai oroszlánok. Sokaknak persze ez talán furcsának tűnik, hiszen az emberek többsége az oroszlánokat afrikai állatként ismeri. Ebben nemRitka indiai oroszlánjaink már több alkalommal szaporodtak is tévednek, ám az oroszlánról tudnivaló, hogy eredeti elterjedési területe jóval nagyobb volt a mainál. Így nemcsak Afrikában számított őshonosnak, hanem Nyugat- és Dél-Ázsiában, sőt még a Balkán-félsziget egyes területein is. Az ógörög eposzokban is azért szerepelnek oroszlánok, mert a homéroszi időkben még előfordultak a mai Görögország területén is. Az ember környezetátalakító tevékenysége, és persze az intenzív vadászat azonban az oroszlánokat egyre inkább visszaszorította, így ma már eredeti elterjedési területük azon részéről, amely Afrikán kívül található, mindenünnen eltűntek. Az egyedüli kivétel az indiai Gudzsarát államban található Gir erdő ma már nemzeti parknak számító védett területe, ahol még élnek oroszlánok. Ezek azonban kissé eltérnek az afrikai rokonaiktól, így a természettudósok önálló alfajnak tekintik őket, illetve mindazokat az oroszlánokat, amelyek egykor Nyugat-Ázsiában is éltek. Ezt az alfajt szokták indiai vagy perzsa oroszlánnak nevezni. Állatkertünkben 2010 óta foglalkozunk indiai oroszlánokkal, s azóta nem egy alkalommal szaporítottuk is őket.'
    },
    {
      id: 2,
      name: 'Medve',
      text: 'A rozsomák Indiában ugyan nem őshonos, de ez is ázsiai elterjedésű fajnak számit. Meg európainak és : Bár a rozsomák nem Indiában él, de részben ázsiai elterjedésűészak-amerikainak is, ugyanis elterjedési terület mindhárom kontinens északi területeire, az ott található tundra és tajga övezetre is kiterjed. A menyétfélék egyik legnagyobb termetű képviselőjének számító állat a történelemelőtti időkben egyébként Magyarországon is élt. Állatkertünkben a múltban csak alkalmilag mutattuk be, 2015 óta azonban nálunk is folyamatosan láthatja őket a közönség. Bár az állatkerti rozsomákok szeretnek elrejtőzni, a mi két állatunkkal elég nagy szerencsénk van, mivel ők gyakran és szívesen mutatják meg magukat a látogatóknak. A rozsomák neve számos nyelven a torkosságra utal. Igaz ez például a német Vielfraß elnevezésre, de a magyar népies torkosborz elnevezés is ebbe a kategóriába tartozik. Magyarországon egyébként sokan onnan ismerhetik a rozsomák nevet, hogy a Szomszédok című, 1987 és 1999 között sugárzott televíziós sorozat egyik szereplőjét is Rozsomáknak hívták. Nem tudni, hogy a Farkas Tamás által megszemélyesített karakternek, akinek „mindig mindenről pontos kimutatása volt", miért pont Rozsomák volt a neve, a sorozat szereplői a történet szerint kezdetben nem is voltak tisztában a név jelentésével. Ám az egyik jelenetben a lexikonra hivatkozva elhangzik, hogy a rozsomák egyébként egy menyétféle ragadozó.'
    },
  ],
  dailyEvents: [
    {
      id: 1,
      name: 'Fóka etetés',
      time: {
        hour: 12,
        minute: 30
      },
      days: [1,2,3,4,5],
      availableDates : [
        ['2019-09-10', '2019-09-20']
      ],
      available: [
        {
          from: '2019-01-01',
          to: '2022-01-01',
          time:'12:00',
          days:[1,2,3]
        }
      ]
    }
  ],
  events: [
    {
      id: 1,
      name: 'Vadvilág világnapja',
      dateType: 1, // one day
      date: '2022-03-03'
    },
    {
      id: 2,
      name: 'Föld fesztivál',
      dateType: 2, // range
      date: {
        from: '2022-04-22',
        to: '2022-04-24'
      }
    }
  ],
  devices: [
    {
      id: 1,
      name: 'Főbejárat'
    },
    {
      id: 2,
      name: 'Elefánt'
    }
  ],
}
<template>
  <div>

    <v-expand-transition v-if="uploading">
      <div class="progress multi-progress">
        <div >
          <div class="percent">{{ percent }} %</div>
          <div class="bytes">{{ showbyte(uploadedSize) }} / {{ showbyte(totalSize) }}</div>
        </div>
        <v-progress-linear v-model="percent"></v-progress-linear>
      </div>
    </v-expand-transition>

    <draggable v-model="items" v-bind="dragOptions" class="row">

      <v-col :cols="size" v-for="id, index in preloads" :key="index">
        <v-img :aspect-ratio="ratio">
          <div class="preload-box fill-height justify-center align-center d-flex">
            <v-progress-circular color="primary" indeterminate></v-progress-circular>
          </div>
        </v-img>
      </v-col>


        <v-col :cols="size" v-for="id in items" :key="id">
          <image-block v-on:imageDelete="deleteImage" :readonly="true" :ratio="ratio" :id="id"></image-block>
        </v-col>

        <v-col :cols="size" key="footer" class="filtered">
          <v-img class="uploader-placeholder" :aspect-ratio="ratio">
              <label class="fill-height">
                <input ref="files" accept="image/jpeg, image/webp, image/png, image/svg" multiple @change="fileChanged" type="file">
                <v-icon>mdi-upload</v-icon>
                <small>tallózás</small>
              </label>
          </v-img>
        </v-col>
    </draggable>
  </div>
</template>

<script>
  import ImageBlock from '../components/ImageBlock.vue';
  import draggable from 'vuedraggable'

  export default {
    components : {
      ImageBlock,
      draggable
    },
    props: [
      'value',
      'ratio',
      'max',
      'size'
    ],
    data() {
      return {
        drag:false,
        items:[],
        uploading:false,
        preloads:[],
        totalSize:0,
        uploadedSize:0,
        percent:0,
        dragOptions:{
          filter: '.filtered',
          animation: 200,
          disabled: false,
          ghostClass: "ghost"
        }
      }
    },

    methods : {
      showbyte (byte) {
        const mb = byte / 1024 / 1024;
        return mb.toFixed(2) + ' MB'
      },
      uploadProgress (progressEvent) {
        this.totalSize = progressEvent.total
        this.uploadedSize = progressEvent.loaded
        this.percent = (this.uploadedSize / this.totalSize * 100).toFixed(1)
      },
      deleteImage (a) {
        const index = this.items.findIndex(x => x === a);
        this.items.splice(index, 1);
      },
      uploaded (response) {
        this.uploading = false;

        response.data.forEach((img) => {
          if (this.max && this.max <= this.items.length) {
            return;
          }
          this.items.unshift(img.id);
        })
        this.preloads = [];
        this.$emit('input', this.items)
      },
      getSrc (img) {
        return `${process.env.VUE_APP_API_BASE_URL}/static/${img.filename}`
      },
      fileChanged () {
        this.uploading = true;

        const arr = [];
        for (var i=0;i< this.$refs.files.files.length; i++) {
          arr.push(this.$refs.files.files[i])
          this.preloads.push(this.$refs.files.files[i])
        }

        this.$api.post('/image/upload', {'images[]': arr}, {
          onUploadProgress: this.uploadProgress,
          headers: {
            "Content-Type": "multipart/form-data"
          }
        })
          .then(this.uploaded)

      }
    },

    mounted() {
      console.log('mo', this.items, this.value)
    },

    watch : {
      value () {
        this.items = this.value || [];
      },
      items () {
        this.$emit('input', this.items)
      }
    }


  }
</script>
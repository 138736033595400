<template>
  <v-container fluid>
    <v-row class="pa-4">
      <v-col class="d-flex">
        <div class="text-h5">Felhasználók</div>
        <v-spacer></v-spacer>
        <v-btn @click="newItem" color="primary">Hozzáadás</v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-data-table
          :headers="headers"
          :items="items"
          :items-per-page="15"
        >
          <template v-slot:[`item.actions`]="{ item }">
            <v-btn  @click="deleteItem=item" icon>
              <v-icon
                small
              >
                mdi-delete
              </v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <v-dialog v-if="createItem" v-model="createItem" width="500">
      <v-card>
        <v-card-title>Hozzáadás</v-card-title>
        <v-card-text>
          <v-text-field :rules="[rules.email]" outlined v-model="createItem.email" label="email cím"></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-btn @click="createItem=null">Mégse</v-btn>
          <v-spacer></v-spacer>
          <v-btn @click="saveItem">Mentés</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-if="deleteItem" v-model="deleteItem" width="500">
      <v-card>
        <v-card-title>Biztosan törlöd a(z) <i>{{deleteItem.email}}</i> felhasználót?</v-card-title>
        <v-card-text>
        </v-card-text>
        <v-card-actions>
          <v-btn @click="deleteItem=null">Mégse</v-btn>
          <v-spacer></v-spacer>
          <v-btn @click="confirmDelete">Igen</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>

  export default {
    data() {
      return {
        headers: [
          {text: 'Email', value: 'email'},
          {text: 'Műveletek', value: 'actions', sortable:false, width: 80},
        ],
        items: [],
        deleteItem: null,
        createItem: null,
        rules: {
          required: value => !!value || 'Kötelező mező',
          email: value => {
            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            return pattern.test(value) || 'Hibás email cím'
          }
        },
      }
    },

    methods : {
      confirmDelete() {
        this.$api.get(`/user/delete/${this.deleteItem.id}`).then(()=>{
          this.deleteItem = null;
          this.loadItems();
        })
      },
      saveItem() {
        this.$api.post(`/user/create`, this.createItem).then(()=>{
          this.createItem = null;
          this.loadItems();
        })
      },
      newItem () {
        this.createItem = {
          email:''
        }
      },
      loadItems() {
        this.$api.get(`/user`).then((resp)=>{
          this.items = resp.data
        })
      }
    },

    mounted () {
      this.loadItems()
    }
  }
</script>
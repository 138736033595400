<template>
  <v-img class="uploader-placeholder" :aspect-ratio="ratio" :src="image" @load="loaded=true">

    <div v-if="needLoader" class="preload-box fill-height justify-center align-center d-flex flex-column">
      <v-progress-circular color="primary" indeterminate></v-progress-circular>
      <div class="progress">
        <div class="percent">{{ percent }} %</div>
        <div class="bytes">{{ showbyte(uploadedSize) }} / {{ showbyte(totalSize) }}</div>
      </div>
    </div>
    <v-btn v-else-if="loaded" @click="deleteImage" fab elevation="2" class="delete-button" color="error">
      <v-icon >mdi-close</v-icon>
    </v-btn>
    <div v-else-if="hasError" class="upload-error">
      <div class="title">Nem megfelelő formátum!</div>
      <div class="text">
        Engedélyezett formátum <b>jpg</b>, <b>webp</b>, <b>png</b>, <b>svg</b>
      </div>
      <v-btn @click="clearError" color="mt-6" small>Másik fájl választása</v-btn>

    </div>
    <label v-else class="fill-height">
      <input ref="files" accept="image/jpeg, image/webp, image/png, image/svg+xml" @change="fileChanged" type="file">
      <v-icon>mdi-upload</v-icon>
      <small>tallózás</small>
    </label>


  </v-img>
</template>

<script>

  export default {

    components : {
    },

    props: [
      'value',
      'ratio',
    ],
    data() {
      return {
        image: null,
        loaded:false,
        uploading:false,
        hasError:false,
        totalSize:0,
        uploadedSize:0,
        percent:0,
      }
    },

    computed : {
      needLoader(){
        return this.uploading || (this.image && !this.loaded)
      }
    },

    methods : {
      showbyte (byte) {
        const mb = byte / 1024 / 1024;

        return mb.toFixed(2) + ' MB'
      },
      uploadProgress (progressEvent) {
        this.totalSize = progressEvent.total
        this.uploadedSize = progressEvent.loaded
        this.percent = (this.uploadedSize / this.totalSize * 100).toFixed(1)
      },
      clearError () {
        this.uploading = false;
        this.hasError = false;
      },
      handleError () {
        this.uploading = false;
        this.hasError = true;
      },
      deleteImage (){
        this.image = null;
        this.loaded = false;
        this.$emit('input', null);
      },
      uploaded (response) {
        this.image = `${process.env.VUE_APP_API_BASE_URL}/static/${response.data[0].filename}`
        this.$emit('input', response.data[0].id)
        this.uploading = false;
      },
      fileChanged () {

        this.uploading = true;
        this.$api.post('/image/upload', {images: this.$refs.files.files[0]}, {
          onUploadProgress: this.uploadProgress,
          headers: {
            "Content-Type": "multipart/form-data"
          }
        })
          .then(this.uploaded)
          .catch(this.handleError)

      },
      loadImage() {
        this.$api.get(`/image/${this.value}`).then((resp) => {
          this.image = `${process.env.VUE_APP_API_BASE_URL}/static/${resp.data.filename}`;
        })
      }
    },

    mounted() {

      if (this.value) {
        this.loadImage()
      }


    },

    watch : {
      value (newValue) {
        if (newValue) {
          this.loadImage()
        }
        else {
          this.image = null;
        }
      }
    }
  }
</script>
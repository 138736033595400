<template>
  <v-container fluid>
    <v-row>
      <v-col>
        <h1>Hello Admin!</h1>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
  export default {

  }
</script>
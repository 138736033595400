import axios from "axios";
import auth from "./auth.js";

const instance = axios.create({
  baseURL: process.env.VUE_APP_API_BASE_URL,
});

instance.interceptors.request.use((config) => {
  const token = auth.$auth.jwt;
  if (token) {
    config.headers['jwt'] = token;
  }
  return config;
});

instance.interceptors.response.use(function (response) {
  return response;
}, function (error) {

  if (error.response.status === 401) {
    auth.$auth.logout()
  }
  return Promise.reject(error);
});


export default {
  install(Vue) {
    Vue.prototype.$api = instance
  },
  $api : instance
};
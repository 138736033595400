<template>
  <v-container fluid>
    <v-row class="pa-4">
      <v-col class="d-flex">
        <div class="text-h5">Állatok</div>
        <v-spacer></v-spacer>
        <v-btn to="/allatok/uj" color="primary">Hozzáadás</v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-data-table
          :headers="headers"
          :items="items"
          :items-per-page="15"
        >
          <template v-slot:[`item.actions`]="{ item }">
            <v-btn :to="`/allatok/${item.id}`" icon class="mr-2">
              <v-icon
                small
              >
                mdi-pencil
              </v-icon>
            </v-btn>
            <v-btn  @click="deleteItem=item" icon>
              <v-icon
                small
              >
                mdi-delete
              </v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <v-dialog v-if="deleteItem" v-model="deleteItem" width="500">
      <v-card>
        <v-card-title>Biztosan törlöd a(z) <i>{{deleteItem.name}}</i> adatlapját?</v-card-title>
        <v-card-text>
        </v-card-text>
        <v-card-actions>
          <v-btn @click="deleteItem=null">Mégse</v-btn>
          <v-spacer></v-spacer>
          <v-btn @click="confirmDelete">Igen</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>

  export default {
    data() {
      return {
        headers: [
          {text: 'Név', value: 'nameHu'},
          {text: 'Műveletek', value: 'actions', sortable:false, width: 120},
        ],
        items: [],
        deleteItem: null,
      }
    },

    methods : {
      confirmDelete() {
        this.$api.get(`/animal/delete/${this.deleteItem.id}`).then(()=>{
          this.loadItems();
          this.deleteItem = null;
          this.loadItems();
        })
      },
      loadItems() {
        this.$api.get(`/animal`).then((resp)=>{
          this.items = resp.data
        })
      }
    },

    mounted () {
      this.loadItems()
    }
  }
</script>
import Vue from 'vue'
import api from '../plugins/api.js'

var app = new Vue({

  data: () => ({
    categories:[],
    pois:[],
    routePoints:[],
    devices:[],
    editedPoi:null,
    editedCategory:null,
    deletePoi:null,
    deleteCategory:null,
    pathPlaceholder:null,
    svg:null,
    width:null,
    height:null,
  }),

  computed : {
  },

  methods: {
    editCategory(){
      this.editedCategory = {
        nameHu:null,
        nameEn:null,
        shape:null,
        contentType:null,
        color:null,
        imageId:null,
      }
    },

    reloadPois () {
      api.$api.get('/poi').then(resp => {
        this.pois = resp.data
      })
    },

    reloadCategories () {
      api.$api.get('/poi-category').then(resp => {
        this.categories = resp.data;
      })
    },

    savePoi(){
      if (this.editedPoi._vm) {
        this.editedPoi._vm = null
      }
      api.$api.post('/poi/save', this.editedPoi)
        .then(() => {
          this.editedPoi = null;
          this.reloadPois();
          this.saveMap();
        })
    },

    saveMap(){

      const file = new File([this.svg.outerHTML], 'map.svg')

      api.$api.post('/map/save', {
        map: file
      }, {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      })
        .then(() => {

        })
    },

    saveCategory(){
      api.$api.post('/poi-category/save', this.editedCategory)
        .then(() => {
          this.editedCategory = null;
          this.reloadCategories()
        })
    },

    getEditedPoiCategory () {
      const category = this.categories.filter(x => x.id == this.editedPoi.categoryId);
      return category.length ? category[0] : {}
    },

    addPoi (x, y) {
      const nearest = this.findNearest(x ,y)
      this.editedPoi = {
        x1: x,
        y1: y,
        x2: nearest.x,
        y2: nearest.y
      }
    },

    doDeletePoi () {
      api.$api.get(`/poi/delete/${this.deletePoi.id}`).then(() => {
        this.deletePoi = null;
        this.reloadPois()
      })
    },

    doDeleteCategory () {
      api.$api.get(`/poi-category/delete/${this.deleteCategory.id}`).then(() => {
        this.deleteCategory = null;
        this.reloadCategories()
      })
    },

    findNearest (x, y) {
      function distance(p) {
        return Math.sqrt(Math.pow(x - p.x, 2) + Math.pow(y - p.y, 2))
      }

      const closest = this.routePoints.reduce((a, b) => distance(a) < distance(b) ? a : b);

      return {
        x: closest.x.toFixed(1),
        y: closest.y.toFixed(1),
      }
    },

    parseRoutes () {
      this.width = this.svg.viewBox.baseVal.width
      this.height = this.svg.viewBox.baseVal.height
      this.svg.getAttribute('viewBox').split(' ')[2]
      this.svg.querySelectorAll('#routes polyline, #routes polygon ').forEach((elem) => {
        for (var i=0; i< elem.points.length; i++) {
          this.routePoints.push(elem.points[i])
        }
      })
    }
  },

  created() {
    this.reloadPois();
    this.reloadCategories();
  },
})

export default {
  install (Vue) {
    Vue.prototype.$map = app
  }
}
import VueRouter from 'vue-router'
import Vue from 'vue'

Vue.use(VueRouter);


import AppHome from "../page/AppHome.vue";
import AnimalsList from "../page/AnimalsList.vue";
import AnimalsEdit from "../page/AnimalsEdit.vue";
import DailyEventsList from "../page/DailyEventsList.vue";
import DailyEventsEdit from "../page/DailyEventsEdit.vue";
import EventsList from "../page/EventsList.vue";
import EventsEdit from "../page/EventsEdit.vue";
import DeviceList from "../page/DeviceList.vue";
import DeviceEdit from "../page/DeviceEdit.vue";
import MapAdmin from "../page/MapAdmin.vue";
import VideoAdmin from "../page/VideoAdmin.vue";
import UserList from "../page/UserList.vue";


const router = new VueRouter({
	routes : [
      { path: '/allatok', component: AnimalsList },
      { path: '/allatok/:id', component: AnimalsEdit },
      { path: '/napi-programok', component: DailyEventsList },
      { path: '/napi-programok/:id', component: DailyEventsEdit },
      { path: '/rendezvenyek', component: EventsList },
      { path: '/rendezvenyek/:id', component: EventsEdit },
      { path: '/eszkozok', component: DeviceList },
      { path: '/eszkozok/:id', component: DeviceEdit },
      { path: '/terkep', component: MapAdmin },
      { path: '/video', component: VideoAdmin },
      { path: '/felhasznalok', component: UserList },
      { path: '/', component: AppHome, redirect: { path: '/eszkozok' }  },
      { path: '*', component: AppHome, redirect: { path: '/eszkozok' }  },
	]
})


/*
router.beforeEach((to, from, next) => {
      if (to.meta.authpage) {
            next()
      } else {
            console.log('auth.isValid', auth.$auth)
            if (auth.isValid) {
                  next()
                  return
            }
            next('/auth')
      }
})

*/
export default router
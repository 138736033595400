<template>
  <v-container fluid>
    <v-row class="pa-4">
      <v-col class="d-flex">
        <div class="text-h5">Térkép szerkesztés</div>
        <v-spacer></v-spacer>
      </v-col>
    </v-row>

    <v-row>

      <v-col>
        <v-card>
          <v-toolbar  dense elevation="0" :color="startAdding ? `light-green` : ``">
            <div v-if="startAdding" style="color:white">
              Kattints a térképre a hely megjelöléséhez
            </div>
            <v-spacer></v-spacer>

            <v-btn icon class="mr-2"  @click="zoomIn"><v-icon>mdi-magnify-plus-outline</v-icon></v-btn>
            <v-btn icon class="mr-6"  @click="zoomOut"><v-icon>mdi-magnify-minus-outline</v-icon></v-btn>
            <v-btn color="primary" small v-if="!startAdding" @click="startAdding =true">Új poi hozzáadása</v-btn>
            <v-btn small v-else @click="startAdding =false">Mégse</v-btn>

          </v-toolbar>

          <v-card-text >
            <div class="zoom-container" :class="{ zoomed: zoom}">
              <div class="map-container" :class="{ adding : startAdding}" @click="add" @mousemove="move">

                <svg-map id="svg-map" ref="svg">
                  <template v-slot:routes>


                    <line v-for="poi in $map.pois"
                          stroke-linejoin="round"
                          stroke-linecap="round"
                          stroke="#F00" stroke-width="2"
                          :key="poi.id"
                          :id="`p-${poi.id}`"
                          :label="poi.nameHu"
                          :x1="poi.x1"
                          :x2="poi.x2"
                          :y1="poi.y1"
                          :y2="poi.y2"
                    ></line>

                    <line v-if="$map.pathPlaceholder" stroke="#F00" stroke-width="4"
                          :x1="$map.pathPlaceholder.x1"
                          :x2="$map.pathPlaceholder.x2"
                          :y1="$map.pathPlaceholder.y1"
                          :y2="$map.pathPlaceholder.y2"></line>

                  </template>
                  <template v-slot:pois>

                    <poi-poi v-for="poi in $map.pois" :key="poi.id" :poi="poi"></poi-poi>

                  </template>
                </svg-map>

              </div>

            </div>
          </v-card-text>
        </v-card>

      </v-col>
    </v-row>


    <v-row>
      <v-col>
        <poi-category class="mb-4"></poi-category>
      </v-col>
      <v-col>
        <poi-list class="mb-4"></poi-list>
      </v-col>
    </v-row>



  </v-container>
</template>

<script>

import SvgMap from '../components/SvgMap.vue';


import PoiCategory from "@/components/PoiCategory";
import PoiList from "@/components/PoiList";
import PoiPoi from "@/components/PoiPoi";

export default {
  components : {
    PoiPoi,
    PoiList,
    PoiCategory,
    SvgMap
  },
  props: [
    'poi'
  ],
  data() {
    return {
      startAdding:false,
      zoom:false
    }

  },

  computed : {

  },

  methods : {

    zoomIn () {
      this.zoom = true;
    },
    zoomOut () {
      this.zoom = false;
    },

    addNew (x1,y1,x2,y2) {
      this.$map.addPoi({
        x1: x1,
        y1: y1,
        x2: closest.x.toFixed(1),
        y2: closest.y.toFixed(1)
      })
    },
    move (e) {
      if (!this.startAdding) {
        return
      }
      const xratio = this.$map.svg.clientWidth / this.$map.svg.viewBox.baseVal.width;
      const yratio = this.$map.svg.clientHeight / this.$map.svg.viewBox.baseVal.height;

      const x = e.layerX / xratio;
      const y = e.layerY / yratio;

      const nearest = this.$map.findNearest(x,y)

      this.$map.pathPlaceholder = {
        x1 : x,
        y1 : y,
        x2 : nearest.x,
        y2 : nearest.y,
      }
    },
    add (e) {
      if (!this.startAdding) {
        return
      }
      const xratio = this.$map.svg.clientWidth / this.$map.svg.viewBox.baseVal.width;
      const yratio = this.$map.svg.clientHeight / this.$map.svg.viewBox.baseVal.height;

      const x = e.layerX / xratio;
      const y = e.layerY / yratio;

      this.$map.addPoi(x, y);

      this.$map.pathPlaceholder = false;
      this.startAdding = false;
    }
  },

  mounted () {

    this.$map.svg = this.$refs.svg.$el;
    this.$map.parseRoutes()
  }
}
</script>
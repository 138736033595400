<template>
  <v-card>
    <v-card-title>
      Kategóriák
      <v-spacer></v-spacer>
      <v-btn @click="$map.editedCategory={}" small color="primary">Hozzáadás</v-btn>
    </v-card-title>
    <v-data-table
        :headers="headers"
        :items="$map.categories"
        :items-per-page="15"
    >
      <template v-slot:[`item.actions`]="{ item }">
        <v-btn @click="editCategory(item)" icon class="mr-2">
          <v-icon small>
            mdi-pencil
          </v-icon>
        </v-btn>
        <v-btn icon @click="$map.deleteCategory=item">
          <v-icon
              small
          >
            mdi-delete
          </v-icon>
        </v-btn>
      </template>

    </v-data-table>


    <v-dialog v-if="$map.editedCategory" v-model="$map.editedCategory" max-width="400" persistent>
      <v-form ref="form" v-model="valid">
      <v-card>
        <v-card-title v-if="$map.editedCategory.id">Kategória szerkesztése</v-card-title>
        <v-card-title v-else>Új kategória</v-card-title>
        <v-card-text>



          <v-tabs v-model="tab">
            <v-tab>
              HU
            </v-tab>
            <v-tab>
              EN
            </v-tab>
          </v-tabs>
          <v-tabs-items v-model="tab">
            <v-tab-item class="pt-4">
              <v-text-field :rules="[rules.required]" outlined label="megnevezes - HU" v-model="$map.editedCategory.nameHu"></v-text-field>
            </v-tab-item>
            <v-tab-item class="pt-4">
              <v-text-field outlined label="megnevezes - EN" v-model="$map.editedCategory.nameEn"></v-text-field>
            </v-tab-item>
          </v-tabs-items>


          <v-row>
            <v-col>
              <p>Alakzat</p>
              <v-btn-toggle v-model="$map.editedCategory.shape">
                <v-btn :value="1">
                  <v-img max-width="30" :src="shape1"></v-img>
                </v-btn>

                <v-btn :value="2">
                  <v-img max-width="30" :src="shape2"></v-img>
                </v-btn>

                <v-btn :value="3">
                  <v-img max-width="30" :src="shape3"></v-img>
                </v-btn>

                <v-btn :value="4">
                  <v-img max-width="30" :src="shape4"></v-img>
                </v-btn>
              </v-btn-toggle>

            </v-col>
          </v-row>


          <v-row v-if="$map.editedCategory.shape <= 3">
            <v-col>
              <p>Szín</p>
              <v-btn-toggle  v-model="$map.editedCategory.color" >
                <v-btn small v-for="color in colors" :key="color" :value="color" :color="color"></v-btn>
              </v-btn-toggle>


            </v-col>
          </v-row>


          <v-row>
            <v-col>
              <p>Tartalom típusa</p>
              <v-btn-toggle  v-model="$map.editedCategory.contentType">
                <v-btn :value="1">Kép</v-btn>
                <v-btn :value="2">Szöveg</v-btn>
              </v-btn-toggle>


            </v-col>
          </v-row>


          <v-row>
            <v-col>
              <v-checkbox label="Kereshető" v-model="$map.editedCategory.searchable"></v-checkbox>
              <v-checkbox label="Csoportosítás" v-model="$map.editedCategory.nearest"></v-checkbox>

            </v-col>
          </v-row>








        </v-card-text>
        <v-card-actions>
          <v-btn @click="resetCategory">Mégse</v-btn>
          <v-spacer></v-spacer>
          <v-btn @click="saveCategory">Mentés</v-btn>
        </v-card-actions>
      </v-card>
      </v-form>
    </v-dialog>

    <v-dialog v-if="$map.deleteCategory" :value="true" width="400">
      <v-card>
        <v-card-title>Biztosan törlöd a(z) <i>{{$map.deleteCategory.nameHu }}</i> kategóriát?</v-card-title>
        <v-card-text>
        </v-card-text>
        <v-card-actions>
          <v-btn @click="$map.deleteCategory=null">Mégse</v-btn>
          <v-spacer></v-spacer>
          <v-btn @click="$map.doDeleteCategory()">Igen</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </v-card>

</template>

<script>

import shape1 from '../assets/map/shape1.svg'
import shape2 from '../assets/map/shape2.svg'
import shape3 from '../assets/map/shape3.svg'
import shape4 from '../assets/map/shape4.svg'


export default {
  components: {
    
  },
  data() {
    return {
      shape1,
      shape2,
      shape3,
      shape4,

      tab:null,
      lastState:null,
      editedItem: null,
      items: [],
      valid:false,
      rules: {
        required: value => !!value || 'Kötelező kitölteni.',
      },
      headers: [
        {
          text: 'Megnevezés',
          value: 'nameHu',
        },
        {text: 'Műveletek', value: 'actions', sortable: false, width: 120},
      ],

      shapes : [
        {id: 1, icon: shape1},
        {id: 2, icon: shape2},
        {id: 3, icon: shape3},
        {id: 4, icon: shape4},
      ],

      colors: [
        "#ee9628",
        "#3371b7",
        "#2fb7ea",
        "#d91f33",
        "#fcb242",
        "#037655",
        "#761d45",
      ]
    }
  },

  methods : {
    editCategory (item) {
      this.lastState = JSON.parse(JSON.stringify(item));
      this.$map.editedCategory = item;
    },
    resetCategory () {
      for (var i in this.lastState) {
        this.$map.editedCategory[i] = this.lastState[i]
      }
      this.$map.editedCategory = null;
    },
    saveCategory() {
      this.$refs.form.validate();
      if (!this.valid) {
        return true;
      }
      this.$map.saveCategory()
    }
  },
  mounted() {
  }
}

</script>
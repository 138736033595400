<template>
	<v-navigation-drawer v-bind="menuOptions" v-resize="$menu.onResize" :value="$menu.isOpen">
		<v-list-item>
			<v-list-item-content>
				<v-list-item-title class="title d-flex align-center justify-center">
          <v-img
              max-width="160"
              :aspect-ratio="4/3" src="../assets/logo.png"></v-img>
				</v-list-item-title>
				<v-list-item-subtitle>
					{{ userName }}
				</v-list-item-subtitle>
			</v-list-item-content>
		</v-list-item>

		<v-divider></v-divider>


		<v-list dense nav>
			<v-list-item link v-for="item, i in menu" :to="item.link" :key="i" >
				<v-list-item-icon>
					<v-icon>{{item.icon}}</v-icon>
				</v-list-item-icon>
				<v-list-item-content>
					<v-list-item-title>{{item.text}}</v-list-item-title>
				</v-list-item-content>
			</v-list-item>
		</v-list>

	</v-navigation-drawer>
</template>

<script>

  import {mdiAccount} from '@mdi/js';
  import { mdiElephant } from '@mdi/js';
  import { mdiAlarm } from '@mdi/js';
  import { mdiCalendar } from '@mdi/js';
  import { mdiMap } from '@mdi/js';
  import { mdiYoutube } from '@mdi/js';
  import { mdiDesktopClassic } from '@mdi/js';

  export default {
    components: {},

    computed: {
      userName() {
        return window.user ? window.user.name : '';
      },
      menuOptions() {
        return {
          app: true,
          stateless: true,
          permanent: true,
          'mini-variant': !this.$menu.isOpen,
        }
      },
      menu() {
        return [
          {icon: mdiElephant, text: 'Állatok', link: '/allatok'},
          {icon: mdiAlarm, text: 'Napi Programok', link: '/napi-programok'},
          {icon: mdiCalendar, text: 'Rendezvények', link: '/rendezvenyek'},
          {icon: mdiMap, text: 'Térkép', link: '/terkep'},
          {icon: mdiYoutube, text: 'Videó lejátszás', link: '/video'},
          {icon: mdiDesktopClassic, text: 'Eszközök', link: '/eszkozok'},
          {icon: mdiAccount, text: 'Felhasználók', link: '/felhasznalok'},
		];
      }
    },

    data() {
      return {
        advertCount: 0,
        favouriteCount: 0,
        savedSearchCount: 0,
      }
    },

    methods: {
    },

    mounted() {
    }
  };
</script>
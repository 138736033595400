<template>
  <v-card :class="padding">
    <card-header :title="`Új jelszó beállítása`"></card-header>

    <v-card-text v-if="!invalidCode">
      <v-form v-model="valid" ref="form">
        <v-text-field @focus="clearError" :persistent-hint="showHint" :hint="hint" id="password"
                      :rules="[rules.required, validFormat]" v-model="pass1" type="password" outlined
                      label="Jelszó"></v-text-field>
        <v-text-field @focus="clearError" :rules="[rules.required, validPassword]" v-model="pass2" type="password"
                      outlined label="Jelszó mégegyszer"></v-text-field>
      </v-form>
    </v-card-text>
    <v-card-text v-else style="color:red;text-align: center">
      Ez a link már nem érvényes.<br>
      Kérjen új jelszó visszaállító linket az emailben.<br>
    </v-card-text>
    <v-card-actions v-if="!invalidCode">
      <v-spacer></v-spacer>
      <v-btn :loading="loading" @click="login" class="text-capitalize" color="primary">Jelszó mentése</v-btn>
    </v-card-actions>
    <v-card-actions v-else>
      <v-spacer></v-spacer>
      <v-btn @click="$auth.forgotPass()" class="text-capitalize" color="primary">Új link kérése</v-btn>
    </v-card-actions>
  </v-card>
</template>
<script>

import CardHeader from "./card-header";


export default {
  components: {
    CardHeader
  },

  data: () => ({
    loading: false,
    pass1: '',
    pass2: '',
    rules: {
      required: value => !!value || 'Kötelező mező'
    },
    passwordError: false,
    invalidCode: false,
    valid: false
  }),

  computed: {
    padding() {
      if (window.innerWidth < 900) {
        return 'pa-0'
      }
      return 'pa-6'
    },
    hint() {
      return !this.pass1 ? 'Legalább 8 karakter amiben van kis és nagybetű is' : null;
    },
    showHint() {
      return !this.pass1;
    },
    validFormat() {
      if (!this.pass1) {
        return true;
      }

      if (this.pass1.length < 8) {
        return `Még legalább ${8 - this.pass1.length} karakter`
      }

      if (!this.pass1.match(/[a-z]/)) {
        return `Tartalmaznia kell kisbetűt is`
      }

      if (!this.pass1.match(/[A-Z]/)) {
        return `Tartalmaznia kell nagybetűt is`
      }

      return true;
    },
    validPassword() {

      if (this.pass1 != this.pass2) {
        return 'Nem egyezik a 2 jelszó';
      }

      if (!this.passwordError) {
        return true;
      }

      return this.passwordError
    }
  },

  methods: {
    clearError() {
      this.passwordError = false;
    },
    login() {
      this.$refs.form.validate();
      if (!this.valid) {
        return false;
      }

      this.loading = true;
      this.$api.post('/user/password/set', {
        password: this.pass1,
        verifyToken: this.$auth.verifyToken
      }).then((response) => {

        this.$snackbar.show('Jelszó elmentve!')
        this.$auth.login(response.data.jwt);
        this.$router.push('/')



      }).catch((error) => {

        if (error.response.data.errors.code) {
          this.invalidCode = true;
        }

        if (error.response.data.errors.password) {
          this.passwordError = 'Nem megfelelo jelszo';
        }

        this.loading = false;
        this.$refs.form.validate();
      })
    }
  },

  mounted() {

  }
}
</script>
<template>
  <v-container fluid>

    <v-row class="pa-4">
      <v-btn :disabled="saveProgress" :loading="saveProgress" @click="save" color="primary">Mentés</v-btn>

      <v-spacer></v-spacer>
      <v-btn small to="/napi-programok" >Vissza a listához</v-btn>
    </v-row>

    <v-form ref="form" v-model="valid">

    <v-row>
      <v-col cols="3">
        <v-card class="fill-height">
          <v-card-title>Listakép</v-card-title>
          <v-card-subtitle>500 x 740px <small>jpg, png, svg, webp</small></v-card-subtitle>
          <v-card-text>
            <v-input :rules="[rules.requiredImage]" v-model="item.listImageId"></v-input>

            <single-image-uploader :ratio="500/740" v-model="item.listImageId"></single-image-uploader>
          </v-card-text>
        </v-card>
      </v-col>



      <v-col cols="9">
        <v-card>
          <v-card-title>Alapadatok</v-card-title>
          <v-card-text>

            <v-tabs v-model="tab">
              <v-tab>
                HU
              </v-tab>
              <v-tab>
                EN
                <span style="color:red" v-if="!item.nameEn">*</span>
                <span style="color:red" v-else-if="!item.descriptionEn">*</span>
              </v-tab>
            </v-tabs>
            <v-tabs-items v-model="tab">
              <v-tab-item class="pt-4">
                <v-text-field :rules="[rules.required]" label="Megnevezés - HU" outlined v-model="item.nameHu"></v-text-field>
                <v-textarea :rules="[rules.required]" maxlength="350" counter="350" rows="10" label="Leírás - HU" outlined v-model="item.descriptionHu"></v-textarea>
              </v-tab-item>
              <v-tab-item class="pt-4" eager>
                <v-text-field class="english" :rules="[rules.required]" label="Megnevezés - EN" outlined v-model="item.nameEn"></v-text-field>
                <v-textarea class="english" :rules="[rules.required]" maxlength="350" counter="350" rows="10" label="Leírás - EN" outlined v-model="item.descriptionEn"></v-textarea>
              </v-tab-item>
            </v-tabs-items>

          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    </v-form>


    <v-row>




      <v-col cols="5">
        <v-card class="fill-height">
          <v-card-title>Háttérkép</v-card-title>
          <v-card-subtitle>1920 x 1080px <small>jpg, png, svg, webp</small></v-card-subtitle>
          <v-card-text>
            <single-image-uploader :ratio="1920/1080" v-model="item.backgroundImageId"></single-image-uploader>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col cols="7">
        <v-card>
          <v-card-title>Időpont</v-card-title>
          <v-card-text class="d-flex">
            <v-select label="Óra" v-model="item.hour" outlined :items="hours"></v-select>
            <div class="pa-4">:</div>
            <v-select label="Perc" v-model="item.minute" outlined :items="minutes"></v-select>
          </v-card-text>
        </v-card>
      </v-col>

    </v-row>



    <v-row>
      <v-col>
      </v-col>
    </v-row>


  </v-container>
</template>
<script>

  import SingleImageUploader from '../components/SingleImageUploader.vue'

  export default {

    components: {
      SingleImageUploader
    },

    data() {
      return {
        tab:null,
        valid:false,
        saveProgress:false,
        radioGroup:null,
        showTimeDialog:true,
        rules: {
          required: value => !!value || 'Kötelező kitölteni.',
          requiredImage: value => !!value || 'Kötelező feltölteni.',
        },
        item : {
          nameHu:'',
          nameEn:'',
          descriptionHu:'',
          descriptionEn:'',
          hour: '',
          minute: '',
          listImageId: null,
          backgroundImageId: null
        },
        hours:['06', '07', '08', '09', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22'],
        minutes:['00','05', '10', '15', '20', '25', '30', '35', '40', '45', '50', '55'],
      }
    },

    computed : {
      isNew(){
        return this.$route.params.id === 'uj'
      }
    },

    methods : {
      addNewTime(){
        this.showTimeDialog=true;
      },
      save() {

        this.$refs.form.validate();
        if (!this.valid) {
          const el = document.querySelector('.v-input.error--text');
          if (el) {
            if (el.classList.contains('english')) {
              this.tab = 1
            }

            const y = el.getBoundingClientRect().top - 100;
            window.scrollTo({top: y, behavior: 'smooth'});
          }

          return true;
        }
        this.saveProgress = true;
        this.$api.post('/daily-event/save', this.item).then((response) => {
          this.$snackbar.show('Módosítások elmentve')
          if (this.isNew) {
            this.$router.push(`/napi-programok/${response.data.id}`)
          }
          this.saveProgress = false;
        })

      }
    },

    mounted () {
      if (!this.isNew) {
        this.$api.get(`/daily-event/${this.$route.params.id}`).then((resp)=>{
          this.item = resp.data;
        })
      }
    }
  }
</script>
import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
console.log('VUE_APP_API_BASE_URL', process.env.VUE_APP_API_BASE_URL)

import './scss/index.scss'

Vue.config.productionTip = false

Vue.use(Menu)
Vue.use(VueCroppie);
import Menu from './plugins/menu'
import Snackbar from './plugins/snackbar'
import router from './plugins/router'
import VueCroppie from 'vue-croppie';
import Api from './plugins/api.js'
import Auth from './plugins/auth.js'
import Map from './plugins/map.js'

Vue.use(Auth)
Vue.use(Api)
Vue.use(Snackbar)
Vue.use(Map)

new Vue({
  vuetify,
  router,
  render: h => h(App)
}).$mount('#app')

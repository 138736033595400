<template>
	<v-app-bar app dense>
		<v-app-bar-nav-icon @click="$menu.toggleMenu">
			<v-icon>{{mdiMenu}}</v-icon>
		</v-app-bar-nav-icon>

		<v-spacer></v-spacer>


		<v-spacer></v-spacer>

		<v-hover v-slot="{hover}">
			<v-btn :color="hover ? 'black' : 'grey'" text small class="text-lowercase" @click="$auth.logout">
				<div id="button-text" :class="{'showed': hover}">kijelentkezés</div>
				<v-icon >{{mdiPower}}</v-icon>
			</v-btn>
		</v-hover>

	</v-app-bar>
</template>

<script>

	import { mdiMenu } from '@mdi/js';
	import { mdiMenuOpen } from '@mdi/js';
	import { mdiPower } from '@mdi/js';


	export default {
		components: {
		},

		data: () => ({
			mdiMenu,
			mdiMenuOpen,
			mdiPower,
		}),

		computed : {

		},

		methods: {

		},

	};
</script>
<template>
  <span>{{ name }}</span>
</template>
<script>
  export default {
    props:['id'],
    data () {
      return {
        name:''
      }
    },
    mounted () {
      this.$api.get(`/playlist/${this.id}`).then((resp) => {
        this.name = resp.data.name;
      })
    }
  }
</script>